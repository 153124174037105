#loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  opacity: 1;
  background-color: rgba(25, 25, 25, 0.85);
  background-image: url(./loading.gif);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100px 100px;
  transition: opacity 0.5s;
}
#loading[rel='0'] {
  display: none;
  opacity: 0;
}
.is-borderless {
  border: none;
}
.border {
  border-style: solid;
}
.border.is-thin {
  border-width: 1px;
}
#logo-cdev {
  max-height: 3rem;
}
#logo-governo {
  max-height: 5rem;
}
.menu a {
  padding-right: 0;
}
.page-title > i {
  color: #1abc9c !important;
}
