#login {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(bg-login.jpg);
  background-attachment: fixed;
  background-size: cover;
}
#login .card {
  border-radius: 0.33rem;
}
